import Base from '@/libs/core/Base'

const fields = {
  name: '',
  product_uid: '',
}

export default class ReferenceUnit extends Base {
  constructor () {
    super(fields)
  }

  store () {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await this.form.submit('post', 'insurance/reference-units', this.getFields())
        this.setFields(fields)
        flash(response)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }

  // update (insuredItemValueId) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let response = await this.form.submit('patch', `insurance/reference-unit-coefficients/${insuredItemValueId}`, this.getFields())
  //       flash(response)
  //       resolve(response)
  //     } catch (err) {
  //       reject(err)
  //     }
  //   })
  // }
}