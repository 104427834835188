<template>
  <base-dialog-form
    :dialog="dialog"
    :loading="loading"
    :width="350"
    title="Create reference unit"
    action-text="Save reference unit details"
    @close="$emit('close')"
    @submit="submit()"
  >
    <template #content>
      <v-text-field
        dense
        outlined
        persistent-hint
        class="rounded-lg"
        label="Name *"
        v-model="referenceUnitObj.name"
        :hint="errors.get('name')"
        :error="errors.has('name')"
        @input="errors.clear('name')"
      ></v-text-field>

      <v-select
        dense
        outlined
        persistent-hint
        class="rounded-lg"
        label="Product *"
        :items="products.data"
        item-text="name"
        item-value="product_uid"
        v-model="referenceUnitObj.product_uid"
        :hint="errors.get('product_uid')"
        :error="errors.has('product_uid')"
        @input="errors.clear('product_uid')"
      ></v-select>
    </template>
  </base-dialog-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ReferenceUnit from '@/libs/insurance/ReferenceUnit'

export default {
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },

    user: {
      type: Object,
    }
  },

  data () {
    return {
      loading: false,
      referenceUnitObj: new ReferenceUnit(),
    }
  },

  computed: {
    ...mapGetters({
      products: 'getInsuranceProducts',
    }),

    errors () {
      return this.referenceUnitObj.form.errors
    }
  },

  methods: {
    ...mapActions([
      'setInsuranceProducts',
    ]),

    submit () {
      if (!this.loading) {
        this.loading = true
        this.referenceUnitObj.store().then(() => {
          this.setReferenceUnits().then(() => {
            this.$emit('close')
          })
        }).finally(() => {
          this.loading = false
        })
      }
    }
  },

  mounted () {
    this.setInsuranceProducts()
  }
}
</script>